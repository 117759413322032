import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";

export default function () {
  const { t } = useI18n();
  /**
   * 表单验证错误消息弹框
   */
  const showValidateErrorMsg = () => {
    Swal.fire({
      text: t("common.submitErrors"),
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: t("common.okGotIt"),
      customClass: {
        confirmButton: "btn btn-primary",
      },
    });
  };

  /**
   * 表单提交成功提示弹框
   */
  const showFormSubmitSuccessMsg = (callback?) => {
    Swal.fire({
      text: t("common.submitSuccess"),
      icon: "success",
      buttonsStyling: false,
      confirmButtonText: t("common.okGotIt"),
      customClass: {
        confirmButton: "btn btn-primary",
      },
    }).then(() => {
      callback();
    });
  };

  /**
   * 显示服务端错误消息弹框
   */
  const showServerErrorMsg = (res) => {
    if (res.sub_code.indexOf("FORM-VALIDATION") != -1) {
      //服务端数据验证错误
      const errorMsg = res.data;
      let htmlMsg = "";
      if (errorMsg) {
        for (const key in errorMsg) {
          htmlMsg += errorMsg[key] ? errorMsg[key][0] + "<br/>" : "";
        }
      }
      Swal.fire({
        title: res.sub_msg,
        icon: "error",
        html: htmlMsg,
      });
    } else {
      //服务端其他错误
      Swal.fire({
        title: res.msg,
        text: res.sub_msg,
        icon: "error",
      });
    }
  };

  return {
    showValidateErrorMsg,
    showFormSubmitSuccessMsg,
    showServerErrorMsg,
  };
}
