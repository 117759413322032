
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiInspection } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { formatDateTime, setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "warehouse-management-inspection-logs",
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const formData = ref({
      article: [] as any[],
      missing: [] as any[],
      mismatched: [] as any[],
      inspection_status: 10,
    });

    const options = ref({});

    const rules = ref({
      // status: [
      //   {
      //     required: true,
      //     message: "Status is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const getInspectionItems = async () => {
      const { data } = await ApiInspection.getInspectionInspectionList({
        id: route.params.id,
      });
      if (data.code === 0) {
        formData.value = data.data;
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDateTime,
      loading,
      options,
      formData,
      formRef,
      rules,
      submitButton,
      getFromInfo,
    };
  },
});
